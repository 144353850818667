// Page for individual Serial Pages.
import React from 'react';
import dayjs from 'dayjs';
import contentParser from 'gatsby-wpgraphql-inline-images';
import { Link } from 'gatsby';
import { last, trim } from 'lodash';
import { DiscussionEmbed } from 'disqus-react';
import FluidImage from '../components/fluid-image.js';

const SerialTemplate = ({ pageContext }) => {
  console.log({pageContext});
  let {
    seriesImage,
    seriesSlug,
    content,
    date,
    nextPage,
    page,
    prevPage,
    pluginOptions,
    image
  } = pageContext;

  // Grab just the first image from the content given,
  // Grab the text after figure, trimming it of whitespace
  let comment = trim(last(content.split(/<figure.*<\/figure>/)), '\n');
  // now we can display the image separate from comment,
  // putting the navigation between them.

  let prettyDate = dayjs(date).format('DD MMMM, YYYY');

  let disqusConfig = {
    identifier: `${seriesSlug}/${page}`,
    title: `${seriesSlug}/${page}`
  };

  return(
    <main className="font-sans w-full">
    <section className="max-w-4xl m-auto flex flex-col items-center">
      <FluidImage image={seriesImage} className='w-full' alt={`banner for ${seriesSlug}`}/>
      <nav className="flex justify-between w-full p-6">
        {prevPage ?(
          <Link to={prevPage}>Previous</Link>
        ) : (
          <em className="opacity-0">previous</em>
        )}
        <h2 className='text-2xl'>Page {page}</h2>
        {nextPage && <Link to={nextPage}>Next</Link>}
        {!nextPage && 
         <em className="opacity-0">next</em>}
      </nav>
      <article className='items-center w-full'>
        <FluidImage
          image={ image }
          className='w-full'
          alt="an incredible drawing"/>
      </article>
      <nav className="flex justify-between w-full p-6 py-4 border-b-4 border-green-200">
        {prevPage ?(
          <Link to={prevPage}>Previous</Link>
        ) : (
          <em className="opacity-0">previous</em>
        )}
        {<Link to={seriesSlug} className="text-2xl">All Pages</Link>}
        {nextPage && <Link to={nextPage}>Next</Link>}
        {!nextPage && 
         <em className="opacity-0">next</em>}
      </nav>
      <div id='annotations' className='text-left w-full px-6 py-4'>
        <em className="text-green-400 font-semibold inline-block mb-2">{prettyDate}</em>
        {contentParser({ content: comment }, pluginOptions)}
      </div>
      <div className="w-full">
      <DiscussionEmbed
        shortname="hicks-testing"
        config={disqusConfig}
        className="w-full"
      />
      </div>
    </section>
    </main>
  );
};

export default SerialTemplate;
